<template>
  <b-container class="user-wallet-container">
    <div id="embed-target1"></div>
    <b-modal
      id="add-payment"
      class="main-modal"
      size="lg"
      centered
      hide-header
      hide-footer
    >
      <div
        class="d-flex justify-content-between align-content-center border-bottom modal-head"
      >
        <div class="d-flex align-items-center gap_2">
          <h3 class="title">إضافة رصيد للمحفظة</h3>
          <h6 class="subtitle">بطاقة ماستر كارد</h6>
        </div>
        <b-button
          @click="$bvModal.hide('add-payment')"
          class="border-0 bg-transparent p-0 m-0 back"
          >رجوع</b-button
        >
      </div>
      <div class="modal-body-content pr-0 pl-0">
        <div class="position-relative mb-1">
          <input-form
            name="add-balance"
            label="الرصيد المطلوب شحنه"
            placeholder="1000"
            v-model="deposit.amount"
            class="d-flex align-items-center add-balance-input gap_2"
          />
          <span class="sub-label">ريال سعودى</span>
        </div>
        <ul
          class="examples d-flex align-items-center justify-content-end gap_1 p-0 mb-5"
        >
          <li
            v-for="(item, index) in [100, 500, 1000, 2000]"
            :key="index"
            @click="examplesCheck(item)"
            :class="{ active: item === deposit.amount }"
            class="d-flex align-items-center justify-content-center"
          >
            {{ item }}
          </li>
        </ul>
        <div
          class="d-flex justify-content-center"
          @click="$bvModal.hide('add-payment')"
        >
          <b-button variant="primary" class="save-modal-btn" @click="addFees"
            >شحن</b-button
          >
        </div>
      </div>
    </b-modal>
    <b-modal
      id="withdraw-payment"
      class="main-modal"
      size="lg"
      centered
      hide-header
      hide-footer
    >
      <div
        class="d-flex justify-content-between align-content-center border-bottom modal-head"
      >
        <div class="d-flex align-items-center gap_2">
          <h3 class="title">سحب رصيد من المحفظة</h3>
        </div>
        <b-button
          @click="$bvModal.hide('withdraw-payment')"
          class="border-0 bg-transparent p-0 m-0 back"
          >رجوع</b-button
        >
      </div>
      <div class="modal-body-content">
        <ValidationObserver v-slot="{ handleSubmit }">
          <form
            class="position-relative p-4"
            @submit.prevent="handleSubmit(withdrawRequest)"
          >
            <div class="position-relative mb-5">
              <input-form
                name="withdraw-balance"
                label="المبلغ المطلوب سحبه"
                placeholder=""
                :validate="'required'"
                v-model="withdraw.amount"
              />
              <span class="sub-label">ريال </span>
            </div>
            <div class="input-wrapper mb-5">
              <label
                for="pin-code"
                class="pin-code-label text-capitalize d-block"
                >pin code</label
              >
              <PincodeInput
                v-model="withdraw.PIN_code"
                placeholder="-"
                :length="5"
                dir="ltr"
              />
            </div>
            <div class="d-flex justify-content-center">
              <b-button
                variant="primary"
                class="save-modal-btn"
                type="submit"
                v-if="!loadingWidthdrawButton"
                >طلب سحب</b-button
              >
              <b-button variant="primary" class="save-modal-btn" v-else>
                <spinner-loading text="يتم تنفيذ الطلب" />
              </b-button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </b-modal>
    <b-row>
      <b-col md="6">
        <balance-card :data="data.currentBalance" classes="user-wallet-primary">
          <div
            class="wallet-options d-flex justify-content-between align-items-center gap_1"
          >
            <div class="d-flex align-items-center gap_1">
              <btn-with-icon
                title="اشحن رصيد"
                icon="las la-plus"
                color="var(--iq-primary)"
                @clickFn="$bvModal.show('add-payment')"
              />
              <btn-with-icon
                title="سحب رصيد"
                icon="las la-redo-alt"
                variant="secondary"
                color="#5a6268"
                @clickFn="$bvModal.show('withdraw-payment')"
              />
            </div>
            <btn-with-icon
              icon="las la-cog"
              variant="warning"
              @clickFn="goToSettings"
            />
          </div>
        </balance-card>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col md="6">
            <balance-card
              :data="data.depositBalance"
              classes="user-wallet-small"
            />
          </b-col>
          <b-col md="6">
            <balance-card
              :data="data.withdrawBalance"
              classes="user-wallet-small"
            />
          </b-col>
          <b-col md="6">
            <balance-card
              :data="data.depositBalanceCount"
              classes="user-wallet-small"
            />
          </b-col>
          <b-col md="6">
            <balance-card
              :data="data.withdrawBalanceCount"
              classes="user-wallet-small"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div class="iq-card mt-4 pay-operations">
      <div
        class="iq-card-body d-flex align-items-center justify-content-between"
      >
        <h3
          style="cursor: pointer"
          class="iq-card-title d-flex align-items-center gap_1"
          @click="showingWalletOperations"
        >
          <i class="las la-wallet"></i>
          <span>العمليات المالية</span>
        </h3>
        <h3
          @click="showingFinancialOperations"
          style="cursor: pointer"
          class="iq-card-title d-flex align-items-center gap_1"
        >
          <!-- <i class="las la-credit-card"></i> -->
          <i class="fab fa-cc-mastercard"></i>
          <span> تفاصيل الدفع بالبطاقة الائتمانية </span>
        </h3>
        <span
          class="filter-icon d-flex align-items-center justify-content-center"
        >
          <i class="las la-filter"></i>
        </span>
      </div>
    </div>
    <!-- wallet card -->
    <div class="walletWrapper" v-if="showWallet">
      <div class="iq-card" v-for="(i, key) in allTransactions" :key="key">
        <div class="iq-card-body">
          <b-row class="align-items-center">
            <b-col md="6">
              <div class="wallet-operation-info d-flex gap_1">
                <div
                  class="icon d-flex align-items-center justify-content-center"
                  v-if="i.type === 'deposit'"
                >
                  <i class="las la-arrow-up"></i>
                </div>
                <div
                  class="icon bg-danger d-flex align-items-center justify-content-center"
                  v-else
                >
                  <i class="las la-arrow-down"></i>
                </div>
                <div>
                  <h6 class="wallet-operation-info-title">
                    {{ i.type }}
                  </h6>
                  <p
                    class="wallet-operation-info-subtitle"
                    v-if="i.type === 'deposit'"
                  >
                    شحن رصيد للمحفظة الخاصة بك
                  </p>
                  <p class="wallet-operation-info-subtitle" v-else>
                    تم سحب رصيد من {{ i.title }}
                  </p>
                  <div class="d-flex align-items-center gap_3">
                    <div
                      class="d-flex align-items-center wallet-operation-info-other"
                    >
                      <i class="las la-file-invoice icon"></i>
                      <span>{{ i.transaction_id }}</span>
                    </div>
                    <div
                      class="d-flex align-items-center wallet-operation-info-other"
                    >
                      <i class="las la-clock icon"></i>
                      <span>{{ i.created_at }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <b-row class="align-items-center">
                <b-col>
                  <div class="wallet-box-info">
                    <h6 class="wallet-box-info-title">نوع العملية</h6>
                    <h6
                      class="wallet-box-info-response"
                      v-if="i.type === 'deposit'"
                    >
                      إيداع
                    </h6>
                    <h6 class="wallet-box-info-response" v-else>سحب</h6>
                  </div>
                </b-col>
                <b-col md="auto">
                  <div
                    class="wallet-box-info for-border"
                    v-if="i.type === 'deposit'"
                  >
                    <h6 class="wallet-box-info-title">طريقة الدفع / الإيداع</h6>
                    <h6 class="wallet-box-info-response">
                      <i class="lab la-apple icon"></i>
                      <span>آبل باي</span>
                    </h6>
                  </div>
                  <div class="wallet-box-info for-border" v-else>
                    <h6 class="wallet-box-info-title">طريقة الدفع / الإيداع</h6>
                    <h6 class="wallet-box-info-response">
                      <i class="las la-wallet icon"></i>
                      <span>المحفظة</span>
                    </h6>
                  </div>
                </b-col>
                <b-col>
                  <div class="wallet-box-info">
                    <h6 class="wallet-box-info-title">المبلغ</h6>
                    <h6
                      class="wallet-box-info-response wallet-box-info-price"
                      v-if="i.type === 'deposit'"
                    >
                      {{ i.amount }}
                    </h6>
                    <h6
                      class="wallet-box-info-response wallet-box-info-price text-danger"
                      v-else
                    >
                      {{ i.amount }}
                    </h6>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="test-form"></div>
      <div class="d-flex justify-content-center">
        <b-pagination
          v-if="pagination.totalItems > pagination.itemsPerPage"
          v-model="pagination.currentPage"
          :total-rows="pagination.totalItems"
          :per-page="pagination.itemsPerPage"
          first-number
          last-number
          class="mb-3 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          align="right"
          @input="getWalletTransaction(pagination.currentPage)"
        >
        </b-pagination>
      </div>
    </div>
    <!-- mastercard -->
    <div class="wrapper-master-card" v-if="showMasterCard">
      <div class="iq-card" v-for="(i, key) in allMasterTransactions" :key="key">
        <div class="iq-card-body">
          <b-row class="align-items-center">
            <b-col md="6">
              <div class="wallet-operation-info d-flex gap_1">
                <div
                  class="icon d-flex align-items-center justify-content-center"
                  v-if="i.type === 'deposit'"
                >
                  <i class="las la-arrow-up"></i>
                </div>
                <div
                  class="icon bg-danger d-flex align-items-center justify-content-center"
                  v-else
                >
                  <i class="las la-arrow-down"></i>
                </div>
                <div>
                  <h6 class="wallet-operation-info-title">
                    {{ i.type }}
                  </h6>
                  <p
                    class="wallet-operation-info-subtitle"
                    v-if="i.type === 'deposit'"
                  >
                    شحن رصيد للمحفظة الخاصة بك
                  </p>
                  <p class="wallet-operation-info-subtitle" v-else>
                    تم سحب رصيد من {{ i.title }}
                  </p>
                  <div class="d-flex align-items-center gap_3">
                    <div
                      class="d-flex align-items-center wallet-operation-info-other"
                    >
                      <i class="las la-file-invoice icon"></i>
                      <span>{{ i.transaction_id }}</span>
                    </div>
                    <div
                      class="d-flex align-items-center wallet-operation-info-other"
                    >
                      <i class="las la-clock icon"></i>
                      <span>{{ i.created_at }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </b-col>
            <b-col md="6">
              <b-row class="align-items-center">
                <b-col>
                  <div class="wallet-box-info">
                    <h6 class="wallet-box-info-title">نوع العملية</h6>
                    <h6
                      class="wallet-box-info-response"
                      v-if="i.type === 'deposit'"
                    >
                      إيداع
                    </h6>
                    <h6 class="wallet-box-info-response" v-else>سحب</h6>
                  </div>
                </b-col>
                <b-col md="auto">
                  <div
                    class="wallet-box-info for-border"
                    v-if="i.type === 'deposit'"
                  >
                    <h6 class="wallet-box-info-title">طريقة الدفع / الإيداع</h6>
                    <h6 class="wallet-box-info-response">
                      <i class="lab la-apple icon"></i>
                      <span>آبل باي</span>
                    </h6>
                  </div>
                  <div class="wallet-box-info for-border" v-else>
                    <h6 class="wallet-box-info-title">طريقة الدفع / الإيداع</h6>
                    <h6 class="wallet-box-info-response">
                      <i class="las la-wallet icon"></i>
                      <span>المحفظة</span>
                    </h6>
                  </div>
                </b-col>
                <b-col>
                  <div class="wallet-box-info">
                    <h6 class="wallet-box-info-title">المبلغ</h6>
                    <h6
                      class="wallet-box-info-response wallet-box-info-price"
                      v-if="i.type === 'deposit'"
                    >
                      {{ i.amount }}
                    </h6>
                    <h6
                      class="wallet-box-info-response wallet-box-info-price text-danger"
                      v-else
                    >
                      {{ i.amount }}
                    </h6>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="test-form"></div>
      <div class="d-flex justify-content-center">
        <b-pagination
          v-if="
            paginationForMaster.totalItems > paginationForMaster.itemsPerPage
          "
          v-model="paginationForMaster.currentPage"
          :total-rows="paginationForMaster.totalItems"
          :per-page="paginationForMaster.itemsPerPage"
          first-number
          last-number
          class="mb-3 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          align="right"
          @input="getWalletTransaction(paginationForMaster.currentPage)"
        >
        </b-pagination>
      </div>
    </div>
  </b-container>
</template>
<script>
/*eslint-disable*/
import { core } from "@/config/pluginInit";
import balanceCard from "../../components/balanceCard";
import btnWithIcon from "../../components/btnWithIcon";
import PincodeInput from "vue-pincode-input";
import websiteServices from "../../services/userProfile";
import mastercardConfig from "@/mixins/mastercardConfig";
import Payment from "@/Utils/payment";

export default {
  name: "wallet",
  mixins: [mastercardConfig],
  data() {
    return {
      showMasterCard: false,
      showWallet: true,
      paymentInfo: {},
      loadingWidthdrawButton: false,
      data: {
        currentBalance: {
          balance: "",
          title: "رصيد محفظتي الحالي",
        },
        depositBalance: {
          balance: "",
          title: "إجمالي الإيداع",
        },
        withdrawBalance: {
          balance: "",
          title: "إجمالي السحب",
        },
        depositBalanceCount: {
          balance: "",
          title: "عدد عمليات الإيداع",
        },
        withdrawBalanceCount: {
          balance: "",
          title: "عدد عمليات السحب",
        },
      },
      // masterData: {
      //   currentBalance: {
      //     balance: "",
      //     title: "رصيد محفظتي الحالي",
      //   },
      //   depositBalance: {
      //     balance: "",
      //     title: "إجمالي الإيداع",
      //   },
      //   withdrawBalance: {
      //     balance: "",
      //     title: "إجمالي السحب",
      //   },
      //   depositBalanceCount: {
      //     balance: "",
      //     title: "عدد عمليات الإيداع",
      //   },
      //   withdrawBalanceCount: {
      //     balance: "",
      //     title: "عدد عمليات السحب",
      //   },
      // },
      withdraw: {
        amount: "",
        PIN_code: "",
      },
      deposit: {
        amount: "",
        payment_method_id: "1",
        currency_id: "1",
      },
      allTransactions: [],
      pagination: {},
      allMasterTransactions: [],
      paginationForMaster: {},
      hyperpayForm: false,
    };
  },
  components: {
    balanceCard,
    btnWithIcon,
    PincodeInput,
  },
  methods: {
    showingWalletOperations() {
      (this.showMasterCard = false), (this.showWallet = true);
      console.log("wallet", this.showMasterCard);
      console.log("wallet", this.showWallet);
    },
    showingFinancialOperations() {
      (this.showMasterCard = true), (this.showWallet = false);
      console.log(this.showMasterCard);
      console.log(this.showWallet);
    },
    async goToPayment(data) {
      console.log("check id is ", data.checkout_id);
      sessionStorage.setItem("initiateData", JSON.stringify(data));
      const returnUrl = window.location.origin + "/payment-status";
      console.log("location origin / payment status  is ", returnUrl);
      // const returnUrl = window.location.origin + `/payment-status?transactionId=${data.transaction_id}`
      const Pay = new Payment(data.gateway.toLowerCase(), data);
      const url = await Pay.paymentConfig();
      console.log("go to payment is , url is", url + data.checkout_id);

      if (data.gateway.toLowerCase() === "hyperpay") {
        this.hyperpayForm = true;
        window.wpwlOptions = {
          onReady: function () {
            console.log("HyperPay widget is ready");
          },
          onComplete: function (result) {
            console.log("Payment completed:", result);
          },
          // Additional configurations as required
        };

        // Load the HyperPay script
        const script = document.createElement("script");
        script.src = url + data.checkout_id;
        script.onload = await function () {
          console.log("HyperPay script loaded");
        };
        document.body.appendChild(script);
        const form = document.createElement("form");
        form.classList.add("paymentWidgets");
        form.setAttribute("data-brands", "VISA MASTER AMEX");
        form.action = returnUrl;
        document.querySelector(".test-form").appendChild(form);
        document.querySelector(".test-form").classList.add("y");
      }
    },

    withdrawRequest() {
      this.loadingWidthdrawButton = true;
      websiteServices
        .requestWithdraw(this.withdraw)
        .then((res) => {
          this.$bvModal.hide("withdraw-payment");
          core.showSnackbar("success", res.data.message);
        })
        .finally(() => {
          this.loadingWidthdrawButton = false;
        });
    },

    examplesCheck(balance) {
      this.deposit.amount = balance;
    },

    goToSettings() {
      this.$router.push({ name: "walletSetings" });
    },
    // getMasterDetails() {
    //   websiteServices.getMasterDetails().then((res) => {
    //     console.log("master details is ..........", res.data);
    //     this.masterData.currentBalance.balance = res.data.balance;
    //     this.masterData.depositBalance.balance = res.data.total_deposit;
    //     this.masterData.withdrawBalance.balance = res.data.total_withdraw;
    //     this.masterData.depositBalanceCount.balance = res.data.deposit_count;
    //     this.masterData.withdrawBalanceCount.balance = res.data.withdraw_count;
    //   });
    // },
    getWallaetDetails() {
      websiteServices.getWallaetDetails().then((res) => {
        this.data.currentBalance.balance = res.data.balance;
        this.data.depositBalance.balance = res.data.total_deposit;
        this.data.withdrawBalance.balance = res.data.total_withdraw;
        this.data.depositBalanceCount.balance = res.data.deposit_count;
        this.data.withdrawBalanceCount.balance = res.data.withdraw_count;
      });
    },
    addFees() {
      websiteServices.deposit(this.deposit).then((res) => {
        websiteServices.initiate(res.data).then((res) => {
          // console.log("..................", res.data);
          this.goToPayment(res.data);
        });
      });
    },
    getWalletTransaction(page = 1) {
      console.log("getWalletTransaction");
      websiteServices.getAllTransactions(page).then((res) => {
        this.allTransactions = res.data.data;
        this.pagination = res.data.meta;
      });
    },

    getMasterTransaction(page = 1) {
      websiteServices.getMasterTransaction(page).then((res) => {
        this.allMasterTransactions = res.data.data;
        this.paginationForMaster = res.data.meta;
      });
    },
  },
  created() {
    this.getWallaetDetails();
    this.getWalletTransaction();
    this.getMasterTransaction();
    this.getMasterDetails();
  },

  mounted() {
    core.index();
  },
};
</script>
<style lang="scss" scoped>
.y {
  height: 110vh;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: white;
  // background: rgba(128, 128, 128, 0.173);
  display: flex;
  align-items: center;
  justify-content: center;
}
.paymentWidgets {
  width: 100%;
  height: 100%;
  background: blue;
}
</style>
